<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndUp ? true : false" style="
        height: 100%;
        background-image: linear-gradient(to bottom, #dcdcdc, #bbedd9);
      ">
    <v-row>
      <v-col>
        <v-card class="memberinfo" width="" flat>
          <v-card-title>
            <span class="title-font-style red--text">APPROVED REJECTED REQUESTS</span>
          </v-card-title>
          <v-divider class="mt-n3" />

          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Application No</th>
                    <th class="text-left">Member No</th>
                    <th class="text-left">Member ID</th>
                    <th class="text-left">Member Name</th>

                    <th class="text-left">Amount</th>
                    <th class="text-left">Approval Status</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="guarantorRequests.length === 0">
                    <tr>
                      <td colspan="6" class="text-center">No data available</td>
                    </tr>
                  </template>
                  <template v-if="guarantorRequests.length > 0">
                    <tr v-for="(item, i) in guarantorRequests" :key="i">
                      <td>{{ item.application_no }}</td>
                      <td>{{ item.member_no }}</td>

                      <td>{{ item.id_no }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.requested_amount }}</td>
                      <td>
                        <v-chip small dark class="ml-2" :color="getStatusColor(item.approval_status)">
                          {{ item.approval_status }}
                        </v-chip>
                      </td>
                      <td v-if="item.approval_status === 'Pending'">
                        <v-btn depressed small color="success" class="ml-2" @click="approveRequest(item)">
                          <span :class="$vuetify.breakpoint.mdAndUp
                            ? 'dep-name'
                            : 'mob-dep-name'
                            ">Approve</span>
                        </v-btn>

                        <v-btn depressed small color="error" class="ma-2" @click="rejectRequest(item)">
                          <span :class="$vuetify.breakpoint.mdAndUp
                            ? 'dep-name'
                            : 'mob-dep-name'
                            ">Reject</span>
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card></v-col>
    </v-row>
  </v-container>
</template>

<script>
import payload from "@/mixin/payloadMethods";
import userInfo from "@/modules/auth/clientInfo";
import FileMixin from "@/mixin/FileMixin";

export default {
  name: "approveReject",

  mixins: [FileMixin],

  beforeRouteEnter(to, from, next) {
    next((v) => {
      const data = {
        f60: "KES",
        f13: payload.methods.getHourMinute(),
        f12: payload.methods.getHourMinuteSecond(),
        f92: userInfo.member_number,
        mti: "0100",
        f11: payload.methods.getMinuteSecond(),
        f68: "Get Guarantors Requests",
        f2: userInfo.phone_number,
        f37: payload.methods.createRefno(),
        f123: "WEB",
        f3: "800600",
        f7: payload.methods.getTransactionDate(),
        f90: userInfo.customer_id,
      };

      v.$store.dispatch("loans/getGuarantorRequests", data);
    });
  },
  data() {
    return {
      isValid: false,
      formData: {
        loanNo: "",
      },
      rules: {
        required: [(value) => !!value || "Required."],
      },
    };
  },

  computed: {
    guarantorRequests() {
      const pendingRequests = this.$store.getters["loans/loansGetters"]("guarantorRequests");
      return pendingRequests ? pendingRequests.filter(request => request.approval_status !== 'Pending') : [];
    },
   
  },
  methods: {
    approveRequest(val) {
      const data = {
        f60: "KES",
        f13: payload.methods.getHourMinute(),
        f12: payload.methods.getHourMinuteSecond(),
        f92: userInfo.member_number,
        f4: val.requested_amount,
        f56: val.application_no,
        mti: "0100",
        f11: payload.methods.getMinuteSecond(),
        f68: "Approve Guarantor Request",
        f2: userInfo.phone_number,
        f37: payload.methods.createRefno(),
        f123: "WEB",
        f3: "800600",
        f7: payload.methods.getTransactionDate(),
        f90: userInfo.customer_id,
      };

      this.$store.dispatch("loans/approveRequest", data);
    },
    rejectRequest(val) {
      const data = {
        f60: "KES",
        f13: payload.methods.getHourMinute(),
        f12: payload.methods.getHourMinuteSecond(),
        f92: userInfo.member_number,
        f4: val.requested_amount,
        f56: val.application_no,
        mti: "0100",
        f11: payload.methods.getMinuteSecond(),
        f68: "Approve Guarantor Request",
        f2: userInfo.phone_number,
        f37: payload.methods.createRefno(),
        f123: "WEB",
        f3: "800600",
        f7: payload.methods.getTransactionDate(),
        f90: userInfo.customer_id,
      };

      this.$store.dispatch("loans/rejectRequest", data);
    },
  },
  watch: {},
  mounted() { },
};
</script>

<style scoped>
@import url("../style.css");
</style>