<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndUp ? true : false" style="
      height: 100%;
      background-image: linear-gradient(to bottom, #dcdcdc, #bbedd9);
    ">
    <v-card flat class="mx-auto memberinfo">
      <v-card-title class="">
        <span class="text-overline red--text"> Loan Application </span>
      </v-card-title>

      <v-card outlined class="mx-6 mt-n3" style="border: 1px solid rgb(61, 65, 61)">
        <v-card-text class="mt-n3">
          <v-row class="mx-3"><v-col class="memberinfo-loan-text">Select Loan Type and fill the below
              details.</v-col></v-row>
          <v-divider class="my-1" />
          <v-row class="mx-3"><v-col class="memberinfo-loan-text">Add Guarantors and ensure your loan is fully
              guaranteed</v-col></v-row>
          <v-divider class="my-1" />
          <v-row class="mx-3"><v-col class="memberinfo-loan-text">If self guarantorship , select yourself as
              guarantor</v-col></v-row>
          <v-divider class="my-1" />
          <v-row class="mx-3"><v-col class="memberinfo-loan-text">Upload Supporting documents</v-col></v-row>
          <v-divider class="my-1" />
          <v-row class="mx-3"><v-col class="memberinfo-loan-text">If above is satisfied, submit the loan</v-col></v-row>
        </v-card-text>
      </v-card>

      <v-divider :class="!$vuetify.breakpoint.mdAndUp ? 'mt-n6' : 'mt-2'" />
      <v-col class="mx-4 application-text mt-n2">
        <v-avatar color="#00a0df" size="25" v-if="$vuetify.breakpoint.mdAndUp">
          <span class="white--text">1</span>
        </v-avatar>
        Loan Application</v-col>

      <v-divider class="mt-n1" />
      <v-card-text class="mt-1">
        <v-form v-model="isValid" ref="eligibilityForm" v-on:submit.prevent>
          <v-row>
            <v-col cols="12" md="3" sm="10" xs="10"><span class="red--text"> Select Loan Type :</span></v-col>
            <v-col cols="12" md="3" sm="10" xs="10" :class="!$vuetify.breakpoint.mdAndUp ? 'mt-n6' : ''">
              <v-autocomplete :items="loanProducts ? loanProducts : []" v-model="loanType" outlined dense
                :rules="rules.required" :item-value="(item) => item" :item-text="(item) => item.product_description"
                label="Select Loan Type" class="small-label"></v-autocomplete></v-col>
            <v-col cols="12" md="3" sm="10" xs="10" :class="!$vuetify.breakpoint.mdAndUp ? 'mt-n10' : ''"><span
                class="red--text"> Basic Salary :</span></v-col>
            <v-col cols="12" md="3" sm="10" xs="10" :class="!$vuetify.breakpoint.mdAndUp ? 'mt-n6' : ''">
              <v-text-field dense outlined label="" placeholder="Basic Salary" v-model="formData.salary"
                :rules="rules.numberValidationRule" class="small-label" /></v-col>
          </v-row>
          <v-row :class="!$vuetify.breakpoint.mdAndUp ? 'mt-n8' : 'mt-n8'">
            <v-col cols="12" md="3" sm="10" xs="10"><span class="red--text">Maximum Amount:</span></v-col>

            <v-col cols="12" md="3" sm="10" xs="10" :class="!$vuetify.breakpoint.mdAndUp ? 'mt-n6' : ''">
              <span>{{
                loanType ? formatNumber(loanType.maximum_loan_amt) : "N/A"
              }}</span>
            </v-col>
            <v-col cols="12" md="3" sm="10" xs="10"><span class="red--text">Loan Amount:</span></v-col>
            <v-col cols="12" md="3" sm="10" xs="10" :class="!$vuetify.breakpoint.mdAndUp ? 'mt-n6' : ''">
              <v-text-field dense outlined label="" placeholder="KSH" v-model="formData.amount"
                :rules="rules.loanValidationRule" class="small-label" /></v-col>

            <v-col cols="12" md="3" sm="10" xs="10" :class="!$vuetify.breakpoint.mdAndUp ? 'mt-n10' : 'mt-n6'"><span
                class="red--text"> Interest :</span></v-col>
            <v-col cols="12" md="3" sm="10" xs="10" :class="!$vuetify.breakpoint.mdAndUp ? 'mt-n6' : 'mt-n6'">
              <span>{{
                loanType ? loanType.interest + "%" : "N/A"
              }}</span></v-col>
            <v-col cols="12" md="3" sm="10" xs="10" :class="!$vuetify.breakpoint.mdAndUp ? 'mt-n4' : 'mt-n6'"><span
                class="red--text"> House Allowance :</span></v-col>
            <v-col cols="12" md="3" sm="10" xs="10" :class="!$vuetify.breakpoint.mdAndUp ? 'mt-n6' : 'mt-n6'">
              <v-text-field dense outlined label="" placeholder="0" v-model="formData.house"
                :rules="rules.numberValidationRule" class="small-label" /></v-col>
          </v-row>
          <v-row :class="!$vuetify.breakpoint.mdAndUp ? 'mt-n2' : 'mt-n2'">
            <v-col cols="12" md="3" sm="10" xs="10" :class="!$vuetify.breakpoint.mdAndUp ? 'mt-n4' : 'mt-n6'"><span
                class="red--text"> Deposit Multiplier :</span></v-col>
            <v-col cols="12" md="3" sm="10" xs="10" :class="!$vuetify.breakpoint.mdAndUp ? 'mt-n6' : 'mt-n6'">
              <span>
                {{ loanType ? formatNumber(Deposit) : "N/A" }}
              </span></v-col>
            <v-col cols="12" md="3" sm="10" xs="10" :class="!$vuetify.breakpoint.mdAndUp ? 'mt-n4' : 'mt-n4'"><span
                class="red--text"> Transport Allowance :</span></v-col>
            <v-col cols="12" md="3" sm="10" xs="10" :class="!$vuetify.breakpoint.mdAndUp ? 'mt-n6' : 'mt-n6'">
              <v-text-field dense outlined label="" placeholder="0" v-model="formData.transport"
                :rules="rules.numberValidationRule" class="small-label" /></v-col>
          </v-row>
          <v-row :class="!$vuetify.breakpoint.mdAndUp ? 'mt-n8' : 'mt-n8'">
            <v-col cols="12" md="3" sm="10" xs="10"><span class="red--text">Installment Period:</span></v-col>
            <v-col cols="12" md="3" sm="10" xs="10" :class="!$vuetify.breakpoint.mdAndUp ? 'mt-n6' : 'mt-2'">
              <span>{{
                loanType ? loanType.installment_period + " month(s)" : "N/A"
              }}</span>
            </v-col>
            <v-col cols="12" md="3" sm="10" xs="10" :class="!$vuetify.breakpoint.mdAndUp ? 'mt-n4' : 'mt-2'"><span
                class="red--text"> Other Allowance :</span></v-col>
            <v-col cols="12" md="3" sm="10" xs="10" :class="!$vuetify.breakpoint.mdAndUp ? 'mt-n6' : 'mt-1'">
              <v-text-field dense outlined label="" placeholder="0" v-model="formData.allowances"
                :rules="rules.numberValidationRule" class="small-label" /></v-col>
            <v-col cols="12" md="3" sm="10" xs="10" :class="!$vuetify.breakpoint.mdAndUp ? 'mt-n10' : 'mt-n6'"><span
                class="red--text">
                Minimum No. of Guarantors :</span></v-col>
            <v-col cols="12" md="3" sm="10" xs="10" :class="!$vuetify.breakpoint.mdAndUp ? 'mt-n6' : 'mt-n6'">
              <span>{{
                loanType ? loanType.min_no_of_guarantors : "N/A"
              }}</span>
            </v-col>

            <v-col cols="12" md="3" sm="10" xs="10" :class="!$vuetify.breakpoint.mdAndUp ? 'mt-n6' : 'mt-n8'"><span
                class="red--text">Deductions:</span></v-col>
            <v-col cols="12" md="3" sm="10" xs="10" :class="!$vuetify.breakpoint.mdAndUp ? 'mt-n6' : 'mt-n8'">
              <v-text-field dense outlined label="" placeholder="0" v-model="formData.deductions"
                :rules="rules.numberValidationRule" class="small-label" /></v-col>





          </v-row>
          <v-row :class="!$vuetify.breakpoint.mdAndUp ? 'mt-2' : 'mt-2'">
            <v-col cols="12" md="3" sm="10" xs="10" :class="!$vuetify.breakpoint.mdAndUp ? 'mt-n6' : 'mt-n6'"><span
                class="red--text"> Loan Purpose :</span></v-col>
            <v-col cols="12" md="3" sm="10" xs="10" :class="!$vuetify.breakpoint.mdAndUp ? 'mt-n6' : 'mt-n6'">
              <v-text-field dense outlined label="" placeholder="" v-model="formData.purpose" :rules="rules.required"
                class="small-label" /></v-col>
            <v-col cols="12" md="3" sm="10" xs="10" :class="!$vuetify.breakpoint.mdAndUp ? 'mt-n2' : 'mt-n6'"><span
                class="red--text"> Period :</span></v-col>
            <v-col cols="12" md="3" sm="10" xs="10" :class="!$vuetify.breakpoint.mdAndUp ? 'mt-n6' : 'mt-n6'">
              <v-text-field dense outlined label="" placeholder="" disabled v-model="formData.period"
                :rules="rules.periodValidationRule" class="small-label" /></v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider class="mt-2" />
      <v-card-actions class="mt-0">
        <v-spacer />

        <v-btn v-if="$vuetify.breakpoint.mdAndUp" depressed class="white--text" color="#25ad64" @click="AppraiseLoan()">
          <span><v-icon small class="mr-2">mdi-content-save</v-icon>submit</span>
        </v-btn>
        <v-btn v-if="!$vuetify.breakpoint.mdAndUp" small depressed class="white--text" color="#25ad64"
          @click="AppraiseLoan()">
          <span><v-icon small class="mr-2">mdi-content-save</v-icon> save and
            continue</span>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="dialog" persistent width="300">
      <v-card :color="changeDialog ? 'secondary' : 'primary'" dark>
        <v-card-text>
          {{
            changeDialog
              ? "Applying For Loan"
              : " Checking Loan Eligibility Status"
          }}

          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="appraisDialog" max-width="500" height="400" persistent>
      <v-card>
        <v-card-title class="text-h5"> {{ textMessage }} </v-card-title>

        <v-card-text class="text-overline justify-center" align="center">
          <v-icon class="vicon" size="50" :color="successMessage && successMessage.f39 === '00'
              ? 'success'
              : 'error'
            ">
            {{
              successMessage && successMessage.f39 === "00"
                ? "mdi-check-circle-outline"
                : "mdi-close-circle-outline"
            }}
          </v-icon>
          <span>{{ successMessage && successMessage.f100 }}</span>
        </v-card-text>

        <v-card-actions>
          <v-btn small color="white--text primary darken-1" @click="appraisDialog = false">
            Cancel</v-btn>
          <v-spacer></v-spacer>

          <v-btn small color="white--text green darken-1" @click="showGuarantorPage = true">
            Proceed to Submit Loan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="eloader" persistent width="500">
      <v-card color="primary" dark>
        <v-card-text>
          Please Wait
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <addGuarantor  :loanGuarantors="loanType.min_no_of_guarantors " v-if="showGuarantorPage" />
    <v-card-text> <router-view /></v-card-text>
  </v-container>
</template>

<script>
import addGuarantor from "./addGuarantor.vue";
import userInfo from "@/modules/auth/clientInfo";
import payload from "@/mixin/payloadMethods";
import { EventBus } from "@/utils/eventBus";
import FileMixin from "@/mixin/FileMixin";
export default {
  name: "LoanApplication",

  mixins: [FileMixin],

  beforeRouteEnter(to, from, next) {
    next((v) => {
      const data = {
        mti: "0100",
        f2: userInfo.phone_number,
        f3: "600001",
        f7: payload.methods.getTransactionDate(),
        f11: payload.methods.getMinuteSecond(),
        f13: payload.methods.getHourMinute(),
        f12: payload.methods.getHourMinuteSecond(),
        f37: payload.methods.createRefno(),
        f68: "Get Loan Products Details",
        f123: "WEB",
        f90: userInfo.customer_id,
        f91: "BOSA",
        f92: userInfo.member_number,
      };

      v.$store.dispatch("loans/getLoanProducts", data);

      const mobdata = {
        mti: "0100",
        f2: userInfo.phone_number,
        f3: "600000",
        f7: payload.methods.getTransactionDate(),
        f11: payload.methods.getMinuteSecond(),
        f13: payload.methods.getHourMinute(),
        f12: payload.methods.getHourMinuteSecond(),
        f37: payload.methods.createRefno(),
        f68: "Get Loan Products Details",
        f123: "WEB",
        f90: userInfo.customer_id,
        f91: "BOSA",
        f92: userInfo.member_number,
      };

      v.$store.dispatch("loans/getMobileLoanProducts", mobdata);

      const freedata = {
        mti: "0200",
        f2: userInfo.phone_number,
        f3: "330000",
        f7: payload.methods.getTransactionDate(),
        f11: payload.methods.getMinuteSecond(),
        f12: payload.methods.getHourMinuteSecond(),
        f13: payload.methods.getHourMinute(),
        f37: payload.methods.createRefno(),
        f60: "KES",
        f68: "Free Account Balance Enquiry",
        f90: userInfo.customer_id,
        f91: "FOSA",
        f123: "WEB",
        f92: userInfo.member_number,
      };

      v.$store.dispatch("Profile/getFreeBalances", freedata);
    });
  },
  components: { addGuarantor },
  data() {
    return {
      loanData: {},
      showGuarantorPage: false,
      successMessage: null,
      appraisDialog: false,
      showButton: false,
      isValid: false,
      sucessDialog: false,
      errorDialog: false,
      redirectDialog: false,
      eloader: false,
      loanAmount: "",
      tabs: null,
      textMessage: "",
      dialog: false,
      changeDialog: false,
      mainDialog: true,
      destinations: [],
      tets: {},
      loanNumber: '',
      formData: {
        repayments: "",
        availableRepayment: "",
        netSalary: "",
        salary: "",
        maxAmount: "",
        takeHome: "",
        amount: "",
        purpose: "",
        allowances: "",
        deductions: "",
        repaymentPeriod: "",
        period: "",
      },
      loanType: null,
      rules: {
        required: [(value) => !!value || "Required."],
        numberValidationRule: [
          (value) => !!value || "Required.",
          (value) => /^[0-9]*$/.test(value) || "Only numbers are allowed.",
        ],
        periodValidationRule: [
          (value) => !!value || "Required.",
          (value) => this.validatePeriod(value),
        ],

        loanValidationRule: [
          (value) => !!value || "Required.",
          (value) => /^[0-9]*$/.test(value) || "Only numbers are allowed.",
          (value) => {
            if (!this.loanType) {
              return "Loan type is not selected.";
            }
            return (
              parseInt(value) <= parseInt(this.loanType.maximum_loan_amt) ||
              "Value exceeds Loan Limit."
            );
          },
        ],
      },
    };
  },

  computed: {
    loanProducts() {
      return this.$store.getters["loans/loansGetters"]("loanProducts");
    },
    freeBalances() {
      return this.$store.getters["Profile/profileGetters"]("freeBalances");
    },
    BOSA() {
      return this.freeBalances !== undefined
        ? this.freeBalances.account_balances_bosa
        : [];
    },
    Deposit() {
      var value = "";
      if (this.BOSA.length !== 0) {
        value = this.BOSA[1].balances;
      }
      return (
        parseInt(this.loanType.deposit_multiplier) *
        parseFloat(value.replace(/,/g, ""))
      ).toFixed(1);
    },
  },
  methods: {
    validatePeriod(value) {
      const enteredPeriod = parseInt(value);
      if (isNaN(enteredPeriod)) {
        return "Please enter a valid number for the period.";
      } else if (enteredPeriod > this.loanType.installment_period) {
        return "Period cannot be greater than Loan Type's installment period";
      } else {
        return true; // Validation passed
      }
    },
    reload() {
      // this.$router.push({ name: "mainDashboard" });
      window.location.reload();
    },

    checkLoanEligibility() {
      if (!this.isValid) {
        this.$refs.eligibilityForm.validate();
      } else {
        if (
          parseInt(this.formData.amount) >
          parseInt(this.loanType.maximum_loan_amt)
        ) {
          this.$toast.error(
            "Amount Entered is greater than Maximum Loan Amount"
          );
        } else {
          this.eloader = true;
          const data = {
            mti: "0200",
            f2: userInfo.phone_number,
            f3: "613000",
            f7: payload.methods.getTransactionDate(),
            f11: payload.methods.getMinuteSecond(),
            f12: payload.methods.getHourMinuteSecond(),
            f13: payload.methods.getHourMinute(),
            f37: payload.methods.createRefno(),
            f60: "KES",
            f68: "CHECK LOAN PRODUCT ELIGIBILITY",
            f123: "WEB",
            f90: userInfo.customer_id,
            f91: this.loanType.product_code,
            f92: userInfo.member_number,
            f93: userInfo.account_number,
            f94: `${this.formData.period}`,
          };
          this.$store.dispatch("loans/checkEligibility", data);
        }
      }
    },
    AppraiseLoan() {
      if (!this.isValid) {
        this.$refs.eligibilityForm.validate();
      } else {
        if (
          parseInt(this.formData.amount) >
          parseInt(this.loanType.maximum_loan_amt)
        ) {
          this.$toast.error(
            "Amount Entered is greater than Maximum Loan Amount"
          );
        } else {
          const data = {
            mti: "0200",
            f2: userInfo.phone_number,
            f3: "800150",
            f4: this.formData.amount,
            f7: payload.methods.getTransactionDate(),
            f11: payload.methods.getMinuteSecond(),
            f12: payload.methods.getHourMinuteSecond(),
            f13: payload.methods.getHourMinute(),
            f37: payload.methods.createRefno(),
            f60: "KES",
            f68: "Loan Appraisal",
            f92: userInfo.member_number,
            f123: "WEB",
            f93: userInfo.account_number,
            f94: this.formData.period,
            f124: this.formData.period,
            f125: this.formData.salary,
            f126: this.formData.allowances,
            f127: this.formData.deductions,
            f122: this.formData.purpose,
            f101: this.loanType.product_code,
          };
          // console.log(data)

          this.$store.dispatch("loans/AppraiseLoan", data);
        }
      }
    },
    ApplyLoan() {
      if (!this.isValid) {
        this.$refs.eligibilityForm.validate();
      } else {
        if (
          parseInt(this.formData.amount) >
          parseInt(this.loanType.maximum_loan_amt)
        ) {
          this.$toast.error(
            "Amount Entered is greater than Maximum Loan Amount"
          );
        } else {
          const data = {
            mti: "0200",
            f2: userInfo.phone_number,
            f3: "800100",
            f4: this.formData.amount,
            f7: payload.methods.getTransactionDate(),
            f11: payload.methods.getMinuteSecond(),
            f12: payload.methods.getHourMinuteSecond(),
            f13: payload.methods.getHourMinute(),
            f37: payload.methods.createRefno(),
            f60: "KES",
            f68: "Loan Application Request",
            f92: userInfo.member_number,
            f123: "WEB",
            f93: userInfo.account_number,
            f94: this.formData.period,
            f124: this.formData.period,
            f125: this.formData.salary,
            f126: this.formData.allowances,
            f127: this.formData.deductions,
            f122: this.formData.purpose,
            f101: this.loanType.product_code,
            f128: this.formData.house,
            f129: this.formData.transport
          };
          // console.log(data)

          this.$store.dispatch("loans/ApplyLoan", data);
        }
      }
    },

    redirect(val) {
      this.$router.push({ name: val });
    },
  },

  mounted() {
    EventBus.$on("openDialog", (val) => {
      this.successMessage = val;
      this.appraisDialog = true;
    });
    EventBus.$on("eligibility", (val) => {
      this.eloader = false;
      if (val === "00") {
        this.showButton = true;
      }
    });
    EventBus.$on("stoploanDialog", (val) => {
      this.$toast.error(val);
      this.dialog = false;
      this.errorDialog = true;
      this.$refs.eligibilityForm.reset();
    });
    EventBus.$on("loanSuccessful", (val) => {
      if (val === "LOAN APPLICATION REQUEST SUCCESS") {
        this.sucessDialog = true;
        this.textMessage = val;
      } else {
        this.errorDialog = true;
        this.textMessage = val;
      }
      // this.$toast.success(val, "Add Guarantors Below");
    });
    EventBus.$on("redirectPage", () => {
      this.$router.push({ name: "loansList" });
    });
    EventBus.$on("loanNo", (val) => {
      this.loanNumber = val
      EventBus.$emit("submit guarantors", { loanNo: val, guarant: this.loanType.min_no_of_guarantors });
    });
    EventBus.$on("submit loan", () => {
      this.ApplyLoan();
      // 
    });
  },
  watch: {
    changeDialog: {
      handler: function () {
        if (this.changeDialog) {
          this.ApplyLoan();
        }
      },
    },
    loanType: {
      handler: function (newLoanType) {
        if (newLoanType) {
          this.tets = { ...newLoanType };
          this.formData.period = this.loanType.installment_period;
        }
      },
      deep: true, // Watch for changes deeply
    },
  },
};
</script>

<style scoped>
@import url("../../profile/style.css");
</style>
