<template>
  <v-container fluid class="fill-height d-flex flex-row align-center main-cont" color="#26c6da"
    v-on:keydown.enter="login('firstTime')">
    <!-- <v-row><v-col cols='12' md="6" sm="12" class="image-col">
      <v-carousel>
    <v-carousel-item
      v-for="(item,i) in Images"
      :key="i"
      :src="item.image_url"
      reverse-transition="fade-transition"
      transition="fade-transition"
    ></v-carousel-item>
  </v-carousel>

    </v-col><v-col cols='12' md="6" sm="12"></v-col></v-row> -->
    <v-row :class="$vuetify.breakpoint.mdAndUp ? 'mt-0' : 'mt-n7'">
      <v-col cols="12" md="12" sm="12" class="justify-center" align="center">
        <v-img src="../../../assets/chuna.png" :height="$vuetify.breakpoint.mdAndUp ? '100' : '50'" contain :class="$vuetify.breakpoint.mdAndUp
          ? 'justify-center'
          : 'justify-center mt-n1'
          " />
        <!-- <span class="title-logo" v-if="$vuetify.breakpoint.mdAndUp"
          >CHUNA DT SACCO</span
        > -->
      </v-col>

      <v-col md="4" sm="12" offset-md="4">
        <v-sheet elevation="2" class="pa-6">
          <v-row>
            <v-col cols="12" md="12">
              <v-card flat>
                <v-alert dense v-if="$store.getters['Auth/alert'].status" :type="$store.getters['Auth/alert'].status === 'success'
                  ? 'success'
                  : 'error'
                  " class="mb-10">
                  {{ $store.getters["Auth/alert"].message }}
                </v-alert>
                <v-col class="justify-center" align="center">
                  <span class="text-overline font-weight-black">
                    Welcome Back!</span><br />
                  <span class="welcome-text"> Sign in to continue</span>
                </v-col>

                <v-card-text class="text-justify mt-2 pb-0">
                  <v-form v-model="isValid" ref="loginForm" v-on:submit.prevent>
                    <v-text-field dense outlined label="Phone Number" placeholder="Enter your Phone Number"
                      v-model="phone" ref="phone" type="number" :rules="rules.required" />

                    <v-text-field dense :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'" outlined label="Pin" placeholder="Enter your Pin"
                      v-model="pin" ref="pin" :rules="rules.required" @click:append="showPassword = !showPassword" />
                  </v-form>
                </v-card-text>

                <v-card-actions class="">
                  <v-row>
                    <v-col cols="12" md="12" sm="12">
                      <v-btn class="btn-success btn-block white--text" color="#E21C21" @click="login('firstTime')">
                        <span>Login</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>

                <div class="alert text-muted">
                  Not Registered ?
                  <v-btn @click="redirect('Register')" text class="btn-linda-mama pink--text">Register Here
                  </v-btn>
                </div>

                <v-row>
                  <v-col class="justify-center mt-n3 mb-6" align="center"><v-row><v-col
                        @click="redirect('Register')"><v-icon color="green">mdi-account-plus</v-icon><br />
                        <span class="text-caption">Open Account</span></v-col>
                      <v-col @click="openHelpDialog()"><v-icon color="green">mdi-help-circle</v-icon><br />
                        <span class="text-caption"> Need Help ?</span></v-col>
                      <v-col><v-icon color="green">mdi-information</v-icon><br />
                        <span class="text-caption">Info</span></v-col></v-row></v-col></v-row>

                <div class="alert text-muted mt-n8" v-if="$vuetify.breakpoint.mdAndUp">
                  For queries ,call us at
                  <strong> 0705951672 / 0733809421 </strong>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>


    <v-snackbar v-model="snackbar">
      Session Ended. Please Login Again.

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="helpDialog" persistent width="600" transition="dialog-bottom-transition">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col class="justify-center" align="center"><v-row class="mt-6"><v-col
                  @click="redirect('Register')"><v-icon color="green">mdi-phone-in-talk</v-icon><br />
                  <span class="text-caption">
                    Call Us at 0705951672</span></v-col>
                <v-col @click="openEmailClient('chunasacco@uonbi.ac.ke')"><v-icon color="green">mdi-email</v-icon><br />
                  <span class="text-caption"> Emai Us</span></v-col>
                <v-col @click="
                  openGoogleMaps(-1.2784846586492975, 36.817925148226784)
                  "><v-icon color="green">mdi-map-marker</v-icon><br />
                  <span class="text-caption">Visit Us</span></v-col></v-row></v-col></v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn small @click="helpDialog = false" color="primary white--text">
            <span>Close</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" width="600" persistent>
      <v-card height="250">
        <v-card-title class="text-button text-center">
          <v-spacer />
          Verify OTP code
          <v-spacer />
          <v-icon @click="dialog = false">mdi-close-circle</v-icon>
        </v-card-title>


        <v-card-text>
          <v-alert outlined v-if="$store.getters['Auth/alert'].status" dense border="left" :type="$store.getters['Auth/alert'].status === 'success'
            ? 'success'
            : 'error'
            " class="mt-3">
            {{ $store.getters["Auth/alert"].message }}
          </v-alert>

          <v-otp-input length="4" v-model="otp_code" @finish="verifyOtp" class="mt-6" />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn small color="success" class="white--text" :loading="$store.getters['loading']" @click="sendOtp">
            Resend OTP
          </v-btn>

          <v-spacer />

          <v-btn small :disabled="otp_code.length < 4" :loading="$store.getters['loading']" @click="verifyOtp"
            color="primary">
            Verify OTP
          </v-btn>
        </v-card-actions>
      </v-card>
      <Spinner />
    </v-dialog>
  </v-container>
</template>

<script>
import { EventBus } from "@/utils/eventBus";
import Spinner from "@/plugins/loader/views/Spinner";
import statconst from "./constants";
// import CryptoJS from "crypto-js";
import payload from "@/mixin/payloadMethods";

export default {
  name: "login",
  components: { Spinner },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Auth/getImages", {"f60":"KES","f13":"0232","f12":"023237","mti":"0100","f11":"3237","f68":"Login Request","f56":"5de7e302e0cfc4df06c5b57ada9df9046fa6fa7d1b66c41d3a40cb910fe1f456","f2":"254728736229","f37":"AAA0AF8MBX","f123":"WEB","f3":"800000","f7":"20240819023237","f90":2199782});
    });
  },
  data: function () {
    return {
      otp_code: "",
      needHelpText: "Need Help",
      locationText: "Location",
      snackbar: false,
      isValid: false,
      isOtpValid: false,
      dialog: false,
      helpDialog: false,
      showPassword: false,
      phone: "", // Initialize phone, pin, and newPin with your data
      pin: "",
      newPin: "",
      refno: "",
      otpformData: {
        otp: "",
        id_number: "",
        source: "WEB",
      },
      rules: {
        required: [(value) => !!value || "Required."],
      },
      colors: [
        "green",
        "secondary",
        "yellow darken-4",
        "red lighten-2",
        "orange darken-1",
      ],
      cycle: true,
      slides: ["First", "Second", "Third", "Fourth", "Fifth"],
    };
  },

  mounted() {
    EventBus.$on("otp-verification", () => {
      this.dialog = true;
      if (this.newData !== undefined && this.user !== undefined) {
        this.validatePin();
      }

    });

    EventBus.$on("validate-otp-code", () => {
      this.dialog = true;

    });
    EventBus.$on("otp-close", () => {
      this.dialog = false;
      // window.location.reload()
    });
    EventBus.$on("sessionEnd", () => {
      setTimeout(() => {
        this.snackbar = true;
      }, 2000);
    });
  },

  computed: {
    user() {
      return this.$store.getters["Auth/authGetters"]("user");
    },
    newData() {
      return this.$store.getters["Auth/authGetters"]("newData");
    },
    Images() {
      return this.$store.getters["Auth/authGetters"]("images");
    },
  },
  methods: {
    login: function (val) {
      if (val === "firstTime") {
        if (!this.isValid) {
          this.$refs.loginForm.validate();
        } else {
          const newPhone = this.phone.replace(/^0|254/g, "");
          const NP = "254" + newPhone;
          const data = {
            f60: "KES",
            f13: payload.methods.getHourMinute(),
            f12: payload.methods.getHourMinuteSecond(),
            mti: statconst.login_mti,
            f11: payload.methods.getMinuteSecond(),
            f68: statconst.customer_details_text,
            f2: NP,
            f37: payload.methods.createRefno(),
            f123: "WEB",
            f3: statconst.customer_code,
            f7: payload.methods.getTransactionDate(),
          };
          // const pinData = {
          //   phone: NP,
          //   pin: this.pin,
          //   new_pin: this.newPin,
          // };

          this.$store.dispatch("Auth/login", data);
          // this.$store.dispatch("Auth/hashPin", pinData);
        }
      }
    },

    validatePin: function () {
      const newPhone = this.phone.replace(/^0|254/g, "");
      const NP = "254" + newPhone;
      const data = {
        f60: "KES",
        f13: payload.methods.getHourMinute(),
        f12: payload.methods.getHourMinuteSecond(),
        mti: statconst.login_mti,
        f11: payload.methods.getMinuteSecond(),
        f68: statconst.login_text,
        f56: payload.methods.generatePasswordHash(this.pin, NP),
        f2: NP,
        f37: payload.methods.createRefno(),
        f123: "WEB",
        f3: statconst.login_code,
        f7: payload.methods.getTransactionDate(),
        f90: this.user.customer_id,
      };
      const allData = {
        validate: data,
        userData: this.user,
      };
      // console.log(data);
      this.$store.dispatch("Auth/validatePin", allData);
    },
    verifyOtp: function () {
      const newPhone = this.phone.replace(/^0|254/g, "");
      const NP = "254" + newPhone;
      const data = {
        mti: "0200",
        f0: "0",
        f2: NP,
        f3: '101016',
        f7: payload.methods.getTransactionDate(),
        f11: payload.methods.getMinuteSecond(),
        f12: payload.methods.getHourMinuteSecond(),
        f13: payload.methods.getHourMinute(),
        f37: payload.methods.createRefno(),
        f60: "KES",
        f68: "Validate OTP",
        f90: this.user.customer_id,
        f123: "WEB",
        f56: this.otp_code,
        f93: this.user.account_number,

      };

      this.$store.dispatch("Auth/validateOtp", data);
    },
    sendOtp: function () {
      const newPhone = this.phone.replace(/^0|254/g, "");
      const NP = "254" + newPhone;
      const data = {
        mti: "0200",
        f0: "0",
        f2: NP,
        f3: '101012',
        f7: payload.methods.getTransactionDate(),
        f11: payload.methods.getMinuteSecond(),
        f12: payload.methods.getHourMinuteSecond(),
        f13: payload.methods.getHourMinute(),
        f37: payload.methods.createRefno(),
        f60: "KES",
        f68: "Request OTP",
        f90: this.user.customer_id,
        f123: "WEB",
        f56: NP,
        f92: NP,
        f93: this.user.account_number,

      };

      this.$store.dispatch("Auth/resendOtp", data);
    },
    redirect(val) {
      this.$router.push({ name: val });
    },
    openHelpDialog() {
      this.helpDialog = true;
    },
    openGoogleMaps(lat, lng) {
      // Construct the Google Maps URL with the latitude and longitude
      const mapsUrl = `https://www.google.com/maps?q=${lat},${lng}`;

      // Open the URL in a new tab or window
      window.open(mapsUrl, "_blank");
    },

    openEmailClient(toEmail) {
      const emailLink = `mailto:${toEmail}`;
      window.location.href = emailLink;
    },
  },
};
</script>

<style scoped>
@import url("../styles.css");

.v-input__append-outer {
  margin-top: 8px;
}
</style>
