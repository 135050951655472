<template>
  <!-- <v-container
    :fluid="$vuetify.breakpoint.mdAndUp ? true : false"
    style="
      height: 100%;
      background-image: linear-gradient(to bottom, #dcdcdc, #bbedd9);
    "
  > -->
  <v-dialog v-model="mainDialog" height="900" persistent width="900">
    <v-card flat max-height="1200">
      <v-card flat class="mx-auto memberinfo">
        <v-card-title class="">
          <span class="text-overline red--text"> Add Guarantor </span>
        </v-card-title>

        <v-card-text class="mt-n3">
          <v-row class="mx-3"><v-col class="memberinfo-loan-text">Add Member Number and Id Number to validate if member
              exists</v-col></v-row>
          <v-divider class="my-1" />
          <v-row class="mx-3"><v-col class="memberinfo-loan-text">Click the Add Button to add member as
              guarantor</v-col></v-row>
          <v-divider class="my-1" />
          <v-row class="mx-3"><v-col class="memberinfo-loan-text">Click the save button to after you have added your
              guarantors</v-col></v-row>
        </v-card-text>

        <v-card-text>
          <v-form v-model="isValid" ref="transferForm" v-on:submit.prevent>
            <span :class="$vuetify.breakpoint.mdAndUp
              ? 'text-overline new-main'
              : 'mob-main-font-style'
              ">
              Enter Member Number</span>

            <v-text-field label="Member Number" v-model="formData.member_number" outlined dense :rules="rules.required"
              type="number" class="small-label"></v-text-field>
            <!-- 
            <span
              :class="
                $vuetify.breakpoint.mdAndUp
                  ? 'text-overline new-main'
                  : 'mob-main-font-style'
              "
            >
              Enter ID Number</span
            >

            <v-text-field
              label="ID Number"
              v-model="formData.id_number"
              outlined
              dense
              :rules="rules.required"
              type="number"
              class="small-label"
            ></v-text-field> -->
          </v-form>
          <v-card-actions>
            <v-spacer />
            <v-btn small depressed class="white--text" color="#25ad64" @click="validateIdNumber()">
              <span><v-icon small class="mr-2">mdi-content-save</v-icon> Validate
                Member</span>
            </v-btn>
          </v-card-actions>
        </v-card-text>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Member Number</th>
                  <th class="text-left">Member ID No</th>
                  <th class="text-left">Member Name</th>
                  <th class="text-left">Email Address</th>
                  <th class="text-left">Phone Number</th>
                  <th class="text-left">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in Guarantors.length > 0 ? Guarantors : []" :key="i">
                  <td>{{ item.member_no }}</td>
                  <td>{{ item.id_number }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.phone }}</td>
                  <td>
                    <v-btn @click="removeGuarantor(item)" small depressed class="white--text" color="#25ad64">
                      <span><v-icon small class="mr-2">mdi-delete</v-icon>Remove</span>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>

        <v-divider class="mt-n4" />
        <v-card-actions class="mt-0">
          <v-spacer />
          <v-btn v-if="$vuetify.breakpoint.mdAndUp" depressed class="white--text" color="#ff4242"
            :to="{ name: 'loansList' }">
            <span><v-icon small class="">mdi-arrow-left</v-icon> Back</span>
          </v-btn>
          <v-btn v-if="$vuetify.breakpoint.mdAndUp" depressed class="white--text" color="#25ad64"
            @click="routeParams !== '' ? saveParamsGuarantor() : saveGuarantor()">
            <span><v-icon small class="mr-2">mdi-content-save</v-icon>save and
              continue</span>
          </v-btn>
          <v-btn v-if="!$vuetify.breakpoint.mdAndUp" depressed class="white--text" color="#ff4242"
            :to="{ name: 'loansList' }">
            <span><v-icon small class="">mdi-arrow-left</v-icon> Back</span>
          </v-btn>
          <v-btn v-if="!$vuetify.breakpoint.mdAndUp" small depressed class="white--text" color="#25ad64"
            @click="routeParams !== '' ? saveParamsGuarantor() : saveGuarantor()">
            <span><v-icon small class="mr-2">mdi-content-save</v-icon> save and
              continue</span>
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-dialog v-model="dialog" persistent width="300">
        <v-card :color="changeDialog ? 'secondary' : 'primary'" dark>
          <v-card-text>
            {{
              changeDialog
                ? "Applying For Loan"
                : " Checking Loan Eligibility Status"
            }}

            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog2" persistent width="500">
        <v-card color="primary" dark>
          <v-card-text>
            Please Wait as We Redirect You
            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
  <!-- </v-container> -->
</template>

<script>
import userInfo from "@/modules/auth/clientInfo";
import payload from "@/mixin/payloadMethods";
import { EventBus } from "@/utils/eventBus";

export default {
  name: "addGuarantor",
  components: {},

  beforeRouteEnter(to, from, next) {
    next((v) => {
      const data = {
        mti: "0100",
        f2: userInfo.phone_number,
        f3: "600001",
        f7: payload.methods.getTransactionDate(),
        f11: payload.methods.getMinuteSecond(),
        f13: payload.methods.getHourMinute(),
        f12: payload.methods.getHourMinuteSecond(),
        f37: payload.methods.createRefno(),
        f68: "Get Loan Products Details",
        f123: "WEB",
        f90: userInfo.customer_id,
        f91: "BOSA",
        f92: userInfo.member_number,
      };

      v.$store.dispatch("loans/getLoanProducts", data);
    });
  },
  props: {
    loanGuarantors: {
      type: String,
      required: false, // This prop is required
    },
  },

  data() {
    return {
      Guarantors: [],
      isValid: false,
      sucessDialog: false,
      errorDialog: false,
      dialog: false,
      dialog2: false,
      changeDialog: false,
      mainDialog: true,
      guarantorAdd: false,
      addG: false,

      formData: {
        member_number: "",
        id_number: "",
      },
      rules: {
        required: [(value) => !!value || "Required."],
      },
    };
  },

  computed: {
    routeParams() {
      return this.$route.params.type ? atob(this.$route.params.type) : '';
    },
    loanProducts() {
      return this.$store.getters["loans/loansGetters"]("loanProducts");
    },
    guarantorsList() {
      return this.$store.getters["loans/loansGetters"]("guarantorsList");
    },
    singleGuarantor() {
      return this.$store.getters["loans/loansGetters"]("singleGuarantor");
    },

  },
  methods: {
    validateIdNumber() {
      if (!this.isValid) {
        this.$refs.transferForm.validate();
      } else {
        const data = {
          mti: "0200",
          f2: userInfo.phone_number,
          f3: "800800",
          f7: payload.methods.getTransactionDate(),
          f11: payload.methods.getMinuteSecond(),
          f12: payload.methods.getHourMinuteSecond(),
          f13: payload.methods.getHourMinute(),
          f37: payload.methods.createRefno(),
          f68: "Validate Id Number",
          f92: this.formData.member_number,
          f56: this.formData.member_number,
          f123: "WEB",

        };

        this.$store.dispatch("loans/validateIdNumber", data);
        this.$refs.transferForm.reset();
        this.addG = true
      }
    },
    saveGuarantor() {
      // const dataObjects = [];

      if (this.Guarantors.length === 0) {

        this.$toast.error("Please Add Guarantor");
      } else {

        if (this.Guarantors.length < parseInt(this.loanGuarantors)) {
          this.$toast.error(
            `Minimum number of Guarantors is ${this.loanGuarantors}`
          );
        } else {
          EventBus.$emit("submit loan");
        }




      }
    },
    saveParamsGuarantor() {
      const dataObjects = [];

      this.Guarantors.forEach((memberObject) => {
        const data = {
          mti: "0200",
          f2: userInfo.phone_number,
          f3: "800500",
          f7: payload.methods.getTransactionDate(),
          f11: payload.methods.getMinuteSecond(),
          f12: payload.methods.getHourMinuteSecond(),
          f13: payload.methods.getHourMinute(),
          f37: payload.methods.createRefno(),
          f68: "Add Guarantor",
          f92: memberObject.member_no,
          f56: this.$route.params.code,
          f123: "WEB",
        };

        dataObjects.push(data);
      });

      this.$store.dispatch("loans/addGuarantor", dataObjects);
    },
    removeGuarantor(objectToRemove) {
      const index = this.Guarantors.indexOf(objectToRemove);
      if (index !== -1) {
        this.Guarantors.splice(index, 1);
        this.singleGuarantor = {}
      }
    },

    redirect(val) {
      this.$router.push({ name: val });
    },
  },

  mounted() {
    EventBus.$on("validatorSuccess", () => {
      // this.$toast.success("Guarantor found");
      this.guarantorAdd = true;
    });
    EventBus.$on("redirectPage", () => {
      this.$router.push({ name: "loansList" });
    });
    EventBus.$on("submit guarantors", (val) => {
      if (this.Guarantors.length < parseInt(val.guarant)) {
        this.$toast.error(
          `Minimum number of Guarantors is ${val.guarant}`
        );
        this.$router.push({ name: "loansList" });
      } else {
        const dataObjects = [];
        this.Guarantors.forEach((memberObject) => {
          const data = {
            mti: "0200",
            f2: userInfo.phone_number,
            f3: "800500",
            f7: payload.methods.getTransactionDate(),
            f11: payload.methods.getMinuteSecond(),
            f12: payload.methods.getHourMinuteSecond(),
            f13: payload.methods.getHourMinute(),
            f37: payload.methods.createRefno(),
            f68: "Add Guarantor",
            f92: memberObject.member_no,
            f56: val.loanNo,
            f123: "WEB",
          };

          dataObjects.push(data);
        });

        this.$store.dispatch("loans/addGuarantor", dataObjects);

      }


    });

    EventBus.$on("uploadImage", (val) => {
      this.$router.push({ name: 'uploadDocuments', params: { code: val } });
    });
  },
  watch: {
    singleGuarantor: {
    handler: function () {
      if (this.singleGuarantor && this.addG) {
        if (this.singleGuarantor.member_no === userInfo.member_number) {
          this.$toast.error("Cannot add yourself as a guarantor.");
        } else {
          // Check if guarantor already exists
          if (!this.Guarantors.find(item => item.member_no === this.singleGuarantor.member_no)) {
            this.Guarantors.push(this.singleGuarantor);
          }
          this.addG = false;
        }
      }
    },
    deep: true,
  },

    guarantorsList: {
      handler: function () {
        if (this.guarantorsList.length > 0) {
          this.guarantorsList.forEach((obj) => {
            const isSimilar = this.Guarantors.some((existingObj) => {
              return Object.keys(obj).every((key) => {
                return obj[key] === existingObj[key];
              });
            });

            if (!isSimilar) {
              this.Guarantors.push(obj);
            }
          });
        }
      },
    },

    loanType: {
      handler: function () {
        if (this.loanType !== null) {
          this.formData.maxAmount = this.loanType.maximum_loan_amt;
          this.formData.repaymentPeriod = this.loanType.installment_period;
          // this.formData = {...this.loanType}
        }
      },
    },
  },
};
</script>

<style scoped>
@import url("../../profile/style.css");
</style>
